import React from "react";
import GameLogoPlaceHolder1 from "../Assets/logo141.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";

const Work = () => {
  const workInfoData = [
    {
      image: GameLogoPlaceHolder1,
      title: "Rope Puzzle 3D",
      link: "https://www.microsoft.com/p/rope-puzzle-3d/9MXV8Q8WXLT0"
    },
    {
      image: ChooseMeals,
      title: "Coming soon",
      link: "https://www.microsoft.com/p/rope-puzzle-3d/9MXV8Q8WXLT0",
    },
    {
      image: DeliveryMeals,
      title: "Coming soon",
      link: "https://www.microsoft.com/p/rope-puzzle-3d/9MXV8Q8WXLT0",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <a href={data.link}>
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
          </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Work;
