import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import {Routes, Route} from "react-router-dom"
import NoPage from "./Components/NoPage";
import PrivacyPolicy from "./Components/PrivacyAndTerms/PrivacyPolicy";
import TermsAndConditions from "./Components/PrivacyAndTerms/TermsAndConditions";
import RopePuzzle3dPrivacyPolicy from "./Components/PrivacyAndTerms/RopePuzzle3dPrivacyPolicy";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<><Home /><Work /><Testimonial /><Footer /></>}></Route>
        <Route path='/about' element={ <About />}/>
        <Route path='/work' element={<Work />}/>
        <Route path='/testimonial' element={<Testimonial />}/>
       
        <Route path='/privacypolicy' element={<PrivacyPolicy />} title="Privacy Policy" />
        <Route path='/termsandconditions' element={<TermsAndConditions />} title="Terms and Conditions"/>
        <Route path='/ropepuzzle3dprivacypolicy' element={<RopePuzzle3dPrivacyPolicy title="Rope Puzzle 3D Privacy Policy" />}/>

        <Route path='/*' element={<NoPage />}/>
       </Routes>
       
    </div>
  );
}

export default App;
