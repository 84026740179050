import React from "react";

const NoPage = () => {
    return (
        <>
        <h1>404 Page not fund</h1>
        <h1><a href="./"> Go Home</a></h1>
        </>
    )
  };
  
  export default NoPage;