import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="primary-heading">What Are Users Saying</h1>
        <p className="primary-text">
          Your experience is our priority.
        </p>
      </div>
      <div className="testimonial-section-bottom">
        {/* <img src={ProfilePic} alt="" /> */}
        <p>FlyingSuper</p>
        <div className="testimonials-stars-container">
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
          <AiFillStar />
        </div>
        <p>
        Rope Puzzle 3D is a fun and challenging puzzle game that tests your spatial awareness and problem solving skills. The graphics are clean and colorful, and the gameplay is intuitive. Overall, a great addition to the puzzle genre. I really like Slices of Games!
        </p>
      
      </div>
    </div>
  );
};

export default Testimonial;
